import './App.css';
import React from 'react';
import Soon from './pages/comingSoon';

function App() {
  return (
    <div className="App">
      
      <Soon />
    </div>
  );
}

export default App;
